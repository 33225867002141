
import axiosClient from "./axiosWrapper";
import logger from "../utils/logger";

// {
//   "gaugeCode": 0,
//   "startDate": "2024-05-28T13:57:49.630Z",
//   "endDate": "2024-05-28T13:57:49.630Z"
// }
export const getSeaMeasurements = async (gaugeCode, startDate, endDate) => {
  let body = `{"gaugeCode": "${gaugeCode}", "startDate": "${startDate ? startDate : new Date().toISOString()}", "endDate": "${endDate ? endDate : new Date().toISOString()}"}` //TODO FIX right values
  let result = await axiosClient().post('/measurements', body)
  logger('getSeaMeasurements', result, startDate, endDate)

  return result
}