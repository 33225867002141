<template>
  <div >
    <!--<h3>Настройки отображения</h3>-->
    <div >
      <div >
      <div style="display: flex; justify-content: space-between; align-items: baseline; gap: 20px; ">
        <div>Отображаемый временной интервал полученных данных (часы)</div>
        <div>
          <md-field class="interval-input" >
            <md-input v-model.trim="receiveInterval" placeholder="12"  type="number" min="1" max="24" step="1"></md-input>
          </md-field>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: baseline; gap: 20px; padding-bottom: 30px">
        <div>Отображаемый временной интервал предпросчета (часы)</div>
        <div>
          <md-field class="interval-input">
            <md-input v-model.trim="forecastInterval" placeholder="1"  type="number" min="1" max="4" step="1"></md-input>
          </md-field>
        </div>
      </div>
      </div>
      <div style="display: flex; width: 750px; overflow-x: auto" >
        <div>
          <div><test-chart legend="Уровень моря" title="График глубины" chart-id="seaLevel" :width="400" :height="400" :settings="seaLevel" setting-name="seaLevel" @updateSettings="handleUpdateSettings"/></div>
        </div>
        <div>
          <div><test-chart legend="Скорость изменения" title="График скорости" chart-id="speed" :settings="seaChangeSpeed" setting-name="seaChangeSpeed"  @updateSettings="handleUpdateSettings"/></div>
        </div>
        <div>
          <div><test-chart legend="Прилив" title="График прилива" chart-id="forecast" :settings="forecast" setting-name="forecast"  @updateSettings="handleUpdateSettings"/></div>
        </div>
      </div>
      <div>
        <div style="display: flex; gap: 20px; justify-content: flex-end; padding: 20px 0">
          <div><input class="dialog-button" type="button" value="Ок" @click="ok" /></div>
          <div><input class="dialog-button"  type="button" value="Отмена" @click="close" /></div>
          <div><input class="dialog-button"  type="button" value="Применить" @click="apply" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TestChart from "./TestChart";
  export default {
    name: 'DataSettingsaForm',
    components: {TestChart},
    props: {
      chartSettings: {
        type: Object,
        default: () => {
          return {
            receiveInterval: 12,
            forecastInterval: 1,
            seaLevel: {
              pointSize: 3,
              lineSize: 2,
              color: '#FF0000'
            },
            seaChangeSpeed: {
              pointSize: 3,
              lineSize: 2,
              color: '#0000FF'
            },
            forecast: {
              pointSize: 3,
              lineSize: 2,
              color: '#008000'
            },
          }
        }
      }
    },
    data() {
      return {
        receiveInterval: this.chartSettings.receiveInterval,
        forecastInterval: this.chartSettings.forecastInterval,
        seaLevel: {
          pointSize: this.chartSettings.seaLevel.pointSize,
          lineSize: this.chartSettings.seaLevel.lineSize,
          color: this.chartSettings.seaLevel.color
        },
        seaChangeSpeed: {
          pointSize: this.chartSettings.seaChangeSpeed.pointSize,
          lineSize: this.chartSettings.seaChangeSpeed.lineSize,
          color: this.chartSettings.seaChangeSpeed.color
        },
        forecast: {
          pointSize: this.chartSettings.forecast.pointSize,
          lineSize: this.chartSettings.forecast.lineSize,
          color: this.chartSettings.forecast.color
        },
      }
    },
    methods: {
      formSettings() {
        return {
          receiveInterval: this.receiveInterval,
          forecastInterval: this.forecastInterval,
          seaLevel: {
            pointSize: this.seaLevel.pointSize,
            lineSize: this.seaLevel.lineSize,
            color: this.seaLevel.color
          },
          seaChangeSpeed: {
            pointSize: this.seaChangeSpeed.pointSize,
            lineSize: this.seaChangeSpeed.lineSize,
            color: this.seaChangeSpeed.color
          },
          forecast: {
            pointSize: this.forecast.pointSize,
            lineSize: this.forecast.lineSize,
            color: this.forecast.color
          },
        }
      },
      handleUpdateSettings(settings) {
        console.log('handleUpdateSettings', settings)
        Object.keys(settings).forEach( key => {
          // this.$set(key, settings[key])
          this[key] = settings[key]
        })
      },
      ok() {
        this.$emit('applyDataSettings', this.formSettings())
      },
      apply() {
        this.$emit('applyDataSettings', this.formSettings())
        this.$emit('closeDataSettingsForm')
      },
      close() {
        this.$emit('closeDataSettingsForm')
      }
    }
  }
</script>

<style>
  .gaugeElem:hover {
    background-color: #9acfea;
    border-color: #2aabd2 !important;
  }

  .interval-input {
    margin: 0 !important;
  }
</style>