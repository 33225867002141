<template>
  <div>
    <md-field  style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >
      <label for="expert-latitude-side">Выберите отображение</label>
      <md-select v-model="typeFilter" name="type-filter" id="type-filter" >
        <md-option :key="0" value="all">Все типы</md-option>
        <md-option :key="1" value="coastal">Прибрежные</md-option>
        <md-option :key="-1" value="dart">Глубоководные</md-option>
      </md-select>
    </md-field>
    <!--<h3>-->
      <!--&lt;!&ndash;Список отображаемых постов&ndash;&gt;-->
      <!--<md-checkbox v-model="coastalEnabled"  class="md-secondary" @click="clickShorePost">Пост</md-checkbox>-->
      <!--<md-checkbox v-model="dartEnabled"  class="md-secondary" @click="clickDartPost">Dart</md-checkbox>-->
    <!--</h3>-->
    <div style="overflow-y: auto; max-height: 55vh; background-color: white; padding-top: 3px">
      <div v-show="!allGauges || !allGauges.length" style="width: 100%; text-align: center">Загрузка...</div>
      <div v-for="gauge in filteredGauges" :key="gauge.code" style="padding: 0 10px; border: 1px solid gray; margin-bottom: 4px" class="gaugeElem">
        <md-checkbox style="margin: 8px 8px 4px 0" v-model="visibleGauges" :value="gauge.code">{{gauge.name}} ({{gauge.latitude}}&#176; c.ш. {{gauge.longitude}}&#176; в.д.)</md-checkbox>
      </div>
    </div>
    <div style="display: flex; padding: 10px 0; justify-content: space-between">
      <div style="display: flex; gap: 20px;">
        <div style="cursor: pointer;  " >
          <input type="button" value="Выделить все" @click="setAll" class="dialog-button"/></div>
        <div style="cursor: pointer;  " >
          <input type="button" value="Снять все" @click="unsetAll"  class="dialog-button"/></div>
      </div>
      <div >
        <div style="cursor: pointer; align-self: flex-end; margin-right: 0" >
          <input type="button" value="Ок" @click="ok"  class="dialog-button"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'
  const filterValues = {
    'all': ['COASTAL', 'DART'],
    'dart': ['DART'],
    'coastal': ['COASTAL']
  }

  export default {
    name: 'GaugeSettingForm',
    props: {
      allGauges: {
        type: Array,
        default: () => {
          return []
        }
      },
      selectedCodes: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data() {
      return {
        typeFilter: 'all',
        visibleGauges: this.allGauges.filter(g => {
          return this.selectedCodes.includes(g.code) && filterValues['all'].includes(g.type)
        }).map(g => g.code)
      }
    },
    computed: {
      ...mapGetters(['getCoastalPointEnabled', 'getDartEnabled']),
      filteredGauges() {
          return this.allGauges.filter(g => {
            return filterValues[this.typeFilter].includes(g.type)
          })
      },
      dartEnabled: {
        get: function() {
          return this.getDartEnabled
        },
        set: function() {
          this.SWITCH_DART_ENABLED()
        }
      },
      coastalEnabled: {
        get: function() {
          return this.getCoastalPointEnabled
        },
        set: function() {
          this.SWITCH_COASTAL_POINT_ENABLED()
        }
      }
    },
    methods: {
      ...mapMutations(['SWITCH_COASTAL_POINT_ENABLED', 'SWITCH_DART_ENABLED']),
      clickShorePost() {
        console.log('coastal')
        this.SWITCH_COASTAL_POINT_ENABLED()
      },
      clickDartPost() {
        console.log('dart')
        this.SWITCH_DART_ENABLED()
      },
      setAll() {
        this.visibleGauges = this.filteredGauges.map(g => g.code)
      },
      unsetAll() {
        this.visibleGauges = []
      },
      ok() {
        console.log(this.visibleGauges)
        this.$emit('onGaugesApply', this.visibleGauges)
        this.$emit('closeGaugeSettingsForm')
      }
    }
  }
</script>

<style>
  .gaugeElem:hover {
    background-color: #9acfea;
    border-color: #2aabd2 !important;
  }

  .dialog-button {
    cursor: pointer;
    width: 120px;
  }
</style>