export default {
  chartSettings: {
    receiveInterval: 12,
    forecastInterval: 1,
    seaLevel: {
      pointSize: 3,
      lineSize: 2,
      color: '#FF0000'
    },
    seaChangeSpeed: {
      pointSize: 3,
      lineSize: 2,
      color: '#0000FF'
    },
    forecast: {
      pointSize: 3,
      lineSize: 2,
      color: '#008000'
    }
  }
}