<template>
  <div>
    <div style="text-align: center; padding-bottom: 20px">{{title}}</div>
    <div class="chart-setup">
      <div style="width: 200px;">
        <label for="colorSet" style="color: rgba(0,0,0,0.54); font-size: 12px">Цвет графика</label>
        <div id="colorSet" style="width: 20px; height: 20px; border: 1px solid black" @click="pickColor = true" :style="{'background-color': selectedColor}"> </div>
        <vue2-color-picker v-model="selectedColor" v-show="pickColor === true" style="position: absolute; background-color: lightgray; z-index: 100" @change="pickColor = false"></vue2-color-picker>
      </div>
    </div>
    <div class="chart-setup">
      <div style="width: 200px">
        <md-field class="interval-input">
          <label for="pointSize">Размер точки</label>
          <md-input id="pointSize" v-model.trim="selectedPointSize" placeholder="3"  type="number" min="1" max="10" step="1"></md-input>
        </md-field></div>
    </div>
    <div class="chart-setup">
      <div style="width: 200px">
        <md-field class="interval-input">
          <label for="lineSize">Размер линии</label>
          <md-input id="lineSize" v-model.trim="selectedLineSize" placeholder="1"  type="number" min="1" max="10" step="1"></md-input>
        </md-field></div>
    </div>
    <div style="width: 250px; height: 250px">
      <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data.sync="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="250"
              :height="250"
              :key="selectedColor + selectedLineSize + selectedPointSize"
      />
    </div>
  </div>
</template>

<script>
  import Vue2ColorPicker from 'vue2-color-picker';
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )

  export default {
    name: 'TestChart',
    components: {
      LineChartGenerator,
      Vue2ColorPicker
    },
    props: {
      settingName: String,
      title: String,
      legend: String,
      settings: {
        type: Object,
        default: () => {
          return {pointSize: 3, lineSize: 2, color: 'red'}
        }
      },
      code: Number,
      chartId: {
        type: String,
        default: 'line-chartX'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 300
      },
      height: {
        type: Number,
        default: 300
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    watch: {
      selectedColor(newVal) {
        this.chartData.datasets[0].backgroundColor = newVal
        this.pickColor = false
        this.chartOptions.elements.line.backgroundColor = newVal
        this.chartOptions.elements.line.borderColor = newVal
        this.chartOptions.elements.point.backgroundColor = newVal
        this.updateSettings()
      },
      selectedPointSize(newVal) {
        if (newVal > 10) return
        this.chartOptions.elements.point.radius = newVal
        this.chartOptions.elements.point.hoverRadius = newVal
        this.updateSettings()
      },
      selectedLineSize(newVal) {
        this.chartOptions.elements.line.borderWidth = newVal
        this.updateSettings()
      }
    },
    data() {
      return {
        selectedColor: this.settings.color,
        selectedPointSize: this.settings.pointSize,
        selectedLineSize: this.settings.lineSize,
        pickColor: this.settings.color,
        chartData: {
          labels: [-2, -1, 0, 1, 2],
          datasets: [
            {
              label: this.legend, // "Уровень моря"
              backgroundColor: this.color,
              data: [
                {x: -2, y: -2},
                {x: -1, y: -1},
                {x: 0, y: 0},
                {x: 1, y: 1},
                {x: 2, y: 2}
              ]
            },
          ]
        },
        chartOptions: {
          elements: {
            point: {
              radius: this.settings.pointSize,
              hoverRadius: this.settings.pointSize, // ex.: to make it bigger when user hovers put larger number than radius.
              backgroundColor: this.settings.color
            },
            line: {
              borderWidth: this.settings.lineSize,
              backgroundColor: this.settings.color,
              borderColor: this.settings.color
            }
          },
          responsive: true,
          maintainAspectRatio: true,
          legend: { //for newer version
            onClick: null
          },
          scales: {
            x: {
              min: -2,
              max: 2,
              ticks: {
                stepSize: 1,  // forces step size to be 50 units
              }
            },
            y: {
              min: -2,
              max: 2,
              ticks: {
                stepSize: 1,  // forces step size to be 50 units
              }
            }
          },
          plugins: { //for older version
            legend: {
              onClick: null
            }
          }
        }
      }
    },
    methods: {
      updateSettings() {
        let result = {}
        result[this.settingName] = {pointSize: this.selectedPointSize, lineSize: this.selectedLineSize, color: this.selectedColor}
        this.$emit('updateSettings', result)
      }
    }
  }
</script>

<style>
  .chart-setup {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-bottom: 20px;
    gap: 10px;
    justify-content: space-between;
    align-items: baseline;
  }



</style>